<template>
  <v-main>
    <v-header
      class="project"
      :headerImage="project.image"
      :displayOverlay="true"
      :overlayCopy="project.title"
    />
    <container type="bg-dark article" id="start">
      <v-row>
        <v-col>
          <p class="mb-3 text-uppercase">
            {{ project.category.join(" and ") }}
          </p>
          <h2>
            <span v-for="(item, id) in project.title" :key="id"
              >{{ item.copy }}
            </span>
          </h2>
          <div v-html="project.fullArticle" />
          <iframe v-if="project.video" class="video" :src="`https://www.youtube.com/embed/${project.video}?autoplay=1&mute=1&loop=1`"/>
        </v-col>
      </v-row>
      <hr />
      <h3 class="text-center mb-3">Other Related Projects</h3>
      <project-grid :filter="project.category" type="dark" />
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import { projects } from "@/data/projects.js";
import ProjectGrid from "@/components/ProjectGrid.vue";

export default {
  name: "Projects",
  components: {
    vHeader,
    ProjectGrid,
  },
  computed: {
    project: function() {
      return projects.filter(
      (project) => project.slug === this.$route.params.name
    )[0]
    }
  },
};
</script>

<style lang="scss">
.article h2 {
  margin-bottom: 1rem;
}
.article p {
  margin-bottom: 2rem !important;
}

.video {
  width: 700px;
  height: 500px;
  border: 0;
}

@media only screen and (max-width: 800px) {
  .video {
    width: 100%;
  }
}
</style>
